import { Component, OnInit } from '@angular/core';
import {Observable, of} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  user$: Observable<any> = null;
  constructor(public authService: AuthService) {
  }

  ngOnInit() {
    this.user$ = this.authService.getLoggedUser();
  }

}
