import { Component, OnInit, OnDestroy } from "@angular/core";
import { fadeInAnimation } from "../../../animations/fade-animation";
import { CommunicationService } from "src/app/services/communication.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
  animations: [fadeInAnimation],
  host: { "[@fadeInAnimation]": "" }
})
export class InvoicesComponent implements OnInit, OnDestroy {

  private currentRoute: string;
  private destroy$ = new Subject();

  constructor(
    private communicationService: CommunicationService,
    public router: Router,
  ) {
    communicationService.emit({ searchBarDisplay: "block" });
    this.currentRoute = this.router.url;
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((routerEvent: NavigationEnd) => {
        this.currentRoute = routerEvent.url;
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.communicationService.emit({ searchBarDisplay: "none" });
    this.destroy$.next();
    this.destroy$.complete();
  }
}
