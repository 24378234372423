import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  @Output() submitFormEvent = new EventEmitter();
  @Output() controlEventEmitter = new EventEmitter<any>();
  @Input() fields: any[] = [];
  @Input() settings: any = {};
  @Input() globals: any = {};
  @Input() autoCompleteOptions: any[] = [];
  form;
  formSubmitted: boolean = false;
  _ = _;

  constructor() {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.buildForm(this.form, null);
  }

  buildForm(controls, group) {
    let loop = this.fields;
    if (group) {
      loop = group;
    }
    for (const field of loop) {
      if (field.hasOwnProperty('items')) {
        controls.addControl(field.name, new FormGroup({}));
        this.buildForm(controls.controls[field.name], field.items);
      } else {
        controls.addControl(field.name, this.buildFormControl(field));
      }
    }
  }

  buildFormControl(field) {
    let validations = [];

    for (const validation of field.validator) {
      if (validation === 'required') {
        validations.push(Validators.required);
      }
      if (validation === 'email') {
        validations.push(Validators.email);
      }
      if (_.includes(validation, 'confirm:')) {
        let splitValidation = _.split(validation, ':');
        field.compare_with = splitValidation[1];
      }

      if (validation === 'password') {
        validations.push(Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#^&+=-])[A-Za-z\d\.$@$!%*?&].{8,}'));
      }
    }
    return new FormControl(field.value, validations);
  }

  controlEventListener(event) {
    this.controlEventEmitter.emit(event);
  }

  onSubmitForm() {
    this.formSubmitted = true;
    if (_.has(this.globals, 'globalError')) {
      this.globals.globalError = null;
    }
    this.submitFormEvent.emit(this.form);
  }

  fillFormValues(values) {
    Object.keys(values).forEach((prop) => {
      this.form.controls[prop].setValue(values[prop]);
    });
  }

  clearForm() {
    this.form.reset();
  }
}
