import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ViewEncapsulation
} from "@angular/core";
import { fadeInAnimation } from "../../../animations/fade-animation";
import {ActivatedRoute, Params, Router} from "@angular/router";
import { MetaInfoHelper } from "../../../services/helpers/MetaInfoHelper";
import i18next from "i18next";
import * as _ from "lodash";
import { FormBuilderComponent } from "src/app/modules/form-builder/form-builder/form-builder.component";
import {
  MatSort,
  MatDialog,
  MatMenuTrigger,
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import {Observable, of} from "rxjs";
import {startWith, map, catchError} from "rxjs/operators";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { CloseConfirmComponent } from "../../modals/close-confirm/close-confirm.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: "app-send-x-invoice",
  templateUrl: "./send-x-invoice.component.html",
  styleUrls: ["./send-x-invoice.component.scss"],
  animations: [
    fadeInAnimation,
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state(
        "expanded",
        style({
          height: "auto",
          visibility: "visible",
          padding: "20px 5px 20px 5px",
          margin: "10px 20px",
          border: "5px solid #efefef"
        })
      ),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ],
  // tslint:disable-next-line:use-host-property-decorator
  host: { "[@fadeInAnimation]": "" }
})
export class SendXInvoiceComponent implements OnInit {
  _ = _;
  isLoading: boolean = false;
  isValidating: boolean = false;
  isValid: boolean = false;
  i18next = i18next;
  invoice: object = {};
  expandedElement: number = 0;
  leitwegIds: Array<any> = [];
  filteredOptions: Observable<string[]>;
  filteredLeitwegNames: Observable<string[]>;
  filteredLeitwegIds: Observable<string[]>;
  leitwegId = null;
  private user$: Observable<Params>;
  paginator = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    length: 0,
    pageIndex: 0
  };
  selectedSenderLeitwegId = null;
  section: number = 1;
  myControl: FormControl = new FormControl();
  leitwegForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    leitweg_id: new FormControl("", [Validators.required])
  });
  typingTimer;
  isAutocompleteUsed: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("addForm") private addForm: FormBuilderComponent;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  apiEndPoint: string;

  constructor(
    public httpClient: HttpClient,
    public router: Router,
    private activeRoute: ActivatedRoute,
    private metaInfoHelper: MetaInfoHelper,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SendXInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService
  ) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  ngOnInit() {
    this.getInvoice(this.data.invoiceId);
    this.getLeitwegIds(this.paginator);
    this.subscribeToFieldChanges();
    this.user$ = this.authService.getLoggedUser();
  }

  getInvoice(uuid) {
    const _self = this;
    const url = this.apiEndPoint + `invoices/${uuid}`;
    this.httpClient.get(url)
      .pipe(
        catchError(e => { console.log(e); return e; })
      ).subscribe ( (response: Params) => {
        _self.invoice = _self.filterData(response);
      },
      error => {
        console.log(error);
      }
    );
  }

  getLeitwegIds(paginator) {
    const _self = this;
    const url = this.apiEndPoint + `leitweg-ids?page=${paginator.pageIndex + 1}&perPage=${
      paginator.pageSize
    }`;
    this.isLoading = true;

    this.httpClient.get(url)
      .pipe(
        catchError(e => { console.log(e); return e; })
      ).subscribe ( (response: Params) => {
        const data = response.data;
        _self.leitwegIds = [];

        data.forEach(element => {
          element.disabled = true;
          _self.leitwegIds.push(element);
        });

        _self.isLoading = false;
      });
  }

  onSubmitForm() {
    if (!this.leitwegForm.valid) {
      return false;
    }

    this.sendXInvoice(this.leitwegForm.value);
  }

  saveLeitwegId(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiEndPoint + "leitweg-ids", formData)
        .pipe(
          catchError(e => {
            if (_.find(e.error, { validation: "unique" })) {
              resolve();
              return;
            }

            console.log(e);
            reject();
            return e;
          })
        ).subscribe((response: Params) => {
          console.log(response);
          resolve();
        }
      );
    });
  }

  continueWithSection2() {
    if (this.selectedSenderLeitwegId) {
      this.leitwegForm.controls.leitweg_id.setValue(
        this.selectedSenderLeitwegId.leitweg_id
      );
      this.leitwegForm.controls.name.setValue(
        this.selectedSenderLeitwegId.name
      );
      this.selectedSenderLeitwegId = null;
    } else {
      const enteredSearch = this.myControl.value;
      const regex = /^([\d-]*$)/;

      if (regex.test(enteredSearch)) {
        this.leitwegForm.controls.leitweg_id.setValue(enteredSearch);
        this.leitwegForm.controls.name.setValue("");
      } else {
        this.leitwegForm.controls.name.setValue(enteredSearch);
        this.leitwegForm.controls.leitweg_id.setValue("");
      }
    }
    this.section = 2;
  }

  filterData(responseData) {
    const uuid = responseData;
    responseData = responseData.invoice;
    const data = {
      uuid,
      id: responseData.id,
      number: responseData.number,
      date: responseData.created_at,
      name: responseData.seller.name,
      accepted: true,
      x_invoice: false,
      x_invoice_status: {},
      gross: {
        price: responseData.documentTotals.total_amount_with_vat,
        currency: responseData.currency_code
      },
      additionalInfo: {
        address: responseData.seller.address.address_line_1,
        contact: {
          telephoneNumber: responseData.seller.contact.telephone_number,
          email: responseData.seller.contact.email
        },
        lines: responseData.lines
      }
    };

    if (responseData.meta.length) {
      const meta = this.metaInfoHelper.transformMetaArrayToObject(
        responseData.meta
      );

      data.x_invoice = meta.hasOwnProperty("x-invoice");

      if (meta.hasOwnProperty("x-invoice-status")) {
        const document = JSON.parse(meta["x-invoice-status"]);
        const xInvoiceStatus = document.documentresponse;
        xInvoiceStatus.accepted = Array.isArray(xInvoiceStatus.accepted);
        data.accepted = xInvoiceStatus.accepted;

        if (xInvoiceStatus.errors.hasOwnProperty("error")) {
          const errorArr = Array.isArray(xInvoiceStatus.errors.error)
            ? xInvoiceStatus.errors.error
            : [xInvoiceStatus.errors.error];
          xInvoiceStatus.errors = errorArr;
        } else {
          xInvoiceStatus.errors = [];
        }

        if (xInvoiceStatus.warnings.hasOwnProperty("warning")) {
          const warningArr = Array.isArray(xInvoiceStatus.warnings.warning)
            ? xInvoiceStatus.warnings.warning
            : [xInvoiceStatus.warnings.warning];
          xInvoiceStatus.warnings = warningArr;
        } else {
          xInvoiceStatus.warnings = [];
        }

        data.x_invoice_status = xInvoiceStatus;
      }
    }

    return data;
  }

  subscribeToFieldChanges() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(val => {
        return val && val.length >= 1 ? this.autoCompleteInput(val) : [];
      })
    );

    this.filteredLeitwegNames = this.leitwegForm.get("name").valueChanges.pipe(
      startWith(""),
      map(val => {
        return val && val.length >= 1
          ? this.autoCompleteInput(val, "name")
          : [];
      })
    );

    this.filteredLeitwegIds = this.leitwegForm
      .get("leitweg_id")
      .valueChanges.pipe(
        startWith(""),
        map(val => {
          return val && val.length >= 1
            ? this.autoCompleteInput(val, "leitweg_id")
            : [];
        })
      );
  }

  autoCompleteInput(val: string, prop: string = null): string[] {
    if (prop) {
      return this.leitwegIds.filter(
        option => option[prop].toLowerCase().indexOf(val.toLowerCase()) === 0
      );
    }

    return this.leitwegIds.filter(
      option =>
        option.leitweg_id.toLowerCase().indexOf(val.toLowerCase()) === 0 ||
        option.name.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }

  async sendXInvoice(formData) {
    this.isLoading = true;
    console.log(formData);
    await this.saveLeitwegId(formData);

    let url = this.apiEndPoint + "peppol/deliver-invoice/" + this.data.invoiceId;
    let self = this;

    this.httpClient.post(url, formData)
      .pipe(
        catchError(e => {
          console.log(e);
          self.isLoading = false;
          return e;
        })
      )
      .subscribe((response: Params) => {
        console.log(response.body);
        self.section = 3;
        self.isLoading = false;
      },
    );
  }

  confirmCloseDialog() {
    if (this.section === 3) {
      this.close(true);
      return false;
    }

    const confirmDialog = this.dialog.open(CloseConfirmComponent, {
      width: "500px",
      data: {
        text: i18next.t("CloseSendingConfirmation"),
        submitText: i18next.t("CancelSending"),
        cancelText: i18next.t("ContinueSending")
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.close(false);
      }
    });
  }

  close(result) {
    this.dialogRef.close(result);
  }

  priceWithSpaces(price): string {
    const parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  changeLeitwegSelection(name, value): void {
    this.selectedSenderLeitwegId = {
      name,
      leitweg_id: value
    };

    this.leitwegForm.controls.leitweg_id.setValue(
      this.selectedSenderLeitwegId.leitweg_id
    );
    this.leitwegForm.controls.name.setValue(this.selectedSenderLeitwegId.name);
  }

  inputLeitwegId(value) {
    this.leitwegForm.reset();
    this.leitwegForm.controls.leitweg_id.setValue(value);
  }

  startValidating(value) {
    this.isValidating = true;
    clearTimeout(this.typingTimer);

    if (value) {
      this.typingTimer = setTimeout(() => {
        this.isValidating = false;
      }, 2000);
    } else {
      this.isValidating = false;
    }
  }

  getErrorMessage(prop) {
    return this.leitwegForm.controls[prop].hasError("required")
      ? i18next.t("RequiredField")
      : "";
  }
}
