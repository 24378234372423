import {Injectable} from '@angular/core';

@Injectable()
export class StringHelperService {
  getParametersFromQueryString(queryString) {
    let paramParts = queryString.split('&');
    let params = {};

    for (let paramPart of paramParts) {
      let paramPartSplitted = paramPart.split('=');
      let paramKey = paramPartSplitted[0];
      let paramValue = paramPartSplitted[1];
      params[paramKey] = paramValue;
    }

    return params;
  }
}
