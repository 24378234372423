import { Component, OnInit } from '@angular/core';
import {Integration} from '../../../../models/integrations/Integration';
import {IntegrationService} from '../../../../services/integrations/IntegrationService';
import {UserIntegration} from '../../../../models/integrations/UserIntegration';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLoading = false;
  integrations: Integration[];
  userIntegrations: Array<UserIntegration> = [];

  constructor(
    private integrationService: IntegrationService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    await this.getUserIntegrations();
    this.redirectIfHasUserIntegrations();
    await this.getIntegrations();
    this.isLoading = false;
  }

  async getIntegrations() {
    this.integrations = await this.integrationService.getIntegrations();
  }

  async getUserIntegrations() {
    try {
      this.userIntegrations = await this.integrationService.getUserIntegrations();
    } catch (e) {
      console.log(e);
    }
  }

  redirectIfHasUserIntegrations() {
    if (this.userIntegrations.length) {
      this.router.navigate(['your-integration/' + this.userIntegrations[0].id]);
    }
  }
}
