import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { MatSort, MatTableDataSource, MatDialog } from "@angular/material";
import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { fadeInAnimation } from "src/app/animations/fade-animation";
import * as _ from "lodash";
import { Params, Router} from "@angular/router";
import { MetaInfoHelper } from "src/app/services/helpers/MetaInfoHelper";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import i18next from "i18next";
import { PdfPreviewComponent } from "../../pdf-preview/pdf-preview.component";
import { SendXInvoiceComponent } from '../../send-x-invoice/send-x-invoice.component';
import { DialogComponent } from 'src/app/components/modals/dialog/dialog.component';
import {InvoiceService} from "../../../../services/http/InvoiceService";
import {catchError, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
registerLocaleData(localeDe);

@Component({
  selector: 'app-all-invoices',
  templateUrl: './all-invoices.component.html',
  styleUrls: ['./all-invoices.component.scss'],
  animations: [
    fadeInAnimation,
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state(
        "expanded",
        style({
          height: "auto",
          visibility: "visible",
          padding: "20px 5px 20px 5px",
          margin: "10px 20px"
        })
      ),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ],
  // tslint:disable-next-line:use-host-property-decorator
  host: { "[@fadeInAnimation]": "" }
})
export class AllInvoicesComponent implements OnInit, OnDestroy {
  columnsToDisplay: string[] = [
    "index",
    "number",
    "date",
    "name",
    "gross_price",
    "status"
  ];
  dataSource: MatTableDataSource<any>;
  paginator = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    length: 0,
    pageIndex: 0
  };
  @ViewChild(MatSort) sort: MatSort;
  expandedElements = [];
  expandedSection: null;
  i18next = i18next;
  _ = _;
  isLoading: boolean = false;
  tooltipMessages = {};
  private destroy$ = new Subject();
  apiEndPoint: string;

  constructor(
    public httpClient: HttpClient,
    public router: Router,
    public dialog: MatDialog,
    private metaInfoHelper: MetaInfoHelper,
    private invoiceService: InvoiceService,
  ) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  ngOnInit() {
    this.tooltipMessages = {
      xInvoiceItem: this.i18next.t("ItemIsXInvoice"),
      validInvoice: this.i18next.t("ValidXInvoice"),
      validateInvoice: this.i18next.t("ValidateXInvoice")
    };

    this.fetchInvoices();
  }

  fetchInvoices() {
    this.isLoading = true;

    const paginator = {
      page: this.paginator.pageIndex,
      perPage: this.paginator.pageSize,
    };

    if (this.router.url === '/invoices/x-invoices') {
      paginator['invoices_meta[0][key]'] = 'x-invoice';
      paginator['invoices_meta[0][value]'] = 1;
    }

    this.invoiceService.fetchInvoices(paginator)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          console.log(error);
          return error;
        })
      )
      .subscribe(
        data => this.manageFetchInvoice(data)
      );
  }

  manageFetchInvoice(data: Params): void {
    data = data.data;
    const dataSourceArr = this.filterDataSource(data);
    this.dataSource = new MatTableDataSource(dataSourceArr);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "date":
          return new Date(item.date);
        case "gross_price":
          return +item.gross.price;
        case "number":
          return +item.number;
        default:
          return item[property];
      }
    };

    this.isLoading = false;
  }

  expandRow(row) {
    if (!this.expandedElements.includes(row.index)) {
      this.expandedElements.push(row.index);
      // this.fetchOrderInfo(row.id);
    } else {
      this.expandedElements = _.without(this.expandedElements, row.index);
    }
  }

  priceWithSpaces(price) {
    const parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  pdfPreview(event, pdfUrl) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(PdfPreviewComponent, {
      width: "800px",
      height: "90%",
      data: {
        pdfUrl
      }
    });
  }

  sendXInvoice(event, invoiceId) {
    event.stopPropagation();
    // this.router.navigate(["send-invoice/" + invoiceId]);

    this.dialog.open(SendXInvoiceComponent, {
      width: '50%',
      minHeight: '300px',
      data: {
        invoiceId
      }
    });
  }

  validateXInvoice(event, invoiceId) {
    event.stopPropagation();
    const _self = this;
    const url = this.apiEndPoint + "/peppol/validate-invoice/" + invoiceId;

    this.isLoading = true;
    this.httpClient.post(url, {})
      .pipe(
        catchError(e => {_self.isLoading = false; return e; })
      )
      .subscribe((response: Params) => {
        _self.fetchInvoices();
      },
    );
  }

  filterDataSource(data) {
    const dataSourceArr: any = [];
    let index = 0;
    const _self = this;

    data.forEach(element => {
      if (!element.invoice) {
        return;
      }

      const dataSource = {
        index: ++index,
        uuid: element.uuid,
        id: element.invoice.id,
        number: element.invoice.number,
        date: element.invoice.created_at,
        name: element.invoice.seller.name,
        type: element.type,
        status: element.status,
        originalPdfSource: null,
        x_invoice: false,
        x_invoice_delivery_status: false,
        x_invoice_status: {
          isValid: false
        },
        gross: {
          price: element.invoice.documentTotals.total_amount_with_vat,
          currency: element.invoice.currency_code
        },
        additionalInfo: {
          address: element.invoice.seller.address.address_line_1,
          contact: {
            telephoneNumber: element.invoice.seller.contact.telephone_number,
            email: element.invoice.seller.contact.email
          },
          lines: element.invoice.lines
        }
      };

      if (element.invoice.additionalSupportingDocuments.length) {
        element.invoice.additionalSupportingDocuments.forEach((documentValue, documentIndex) => {
          if (documentValue.document_reference === 'Original Invoice') {
            dataSource.originalPdfSource = documentValue.external_document_location;
          }
        });
      }

      if (element.invoice.meta.length) {
        const meta = _self.metaInfoHelper.transformMetaArrayToObject(
          element.invoice.meta
        );

        dataSource.x_invoice = meta.hasOwnProperty("x-invoice");

        if (meta.hasOwnProperty("x-invoice-deliver-status")) {
          const document = JSON.parse(meta["x-invoice-deliver-status"]);
          const deliveryStatus = document.documentresponse;
          const isAccepted = Array.isArray(deliveryStatus.accepted);
          dataSource.x_invoice_delivery_status = isAccepted;
        }

        if (dataSource.x_invoice && !dataSource.x_invoice_delivery_status) {
          this.expandedElements.push(dataSource.index);
        }

        if (meta.hasOwnProperty("x-invoice-status")) {
          const document = JSON.parse(meta["x-invoice-status"]);
          const xInvoiceStatus = document.documentresponse;
          xInvoiceStatus.isValid = true;
          xInvoiceStatus.accepted = Array.isArray(xInvoiceStatus.accepted);

          if (xInvoiceStatus.errors.hasOwnProperty("error")) {
            const errorArr = Array.isArray(xInvoiceStatus.errors.error)
              ? xInvoiceStatus.errors.error
              : [xInvoiceStatus.errors.error];
            xInvoiceStatus.errors = errorArr;
          } else {
            xInvoiceStatus.errors = [];
          }

          if (xInvoiceStatus.warnings.hasOwnProperty("warning")) {
            const warningArr = Array.isArray(xInvoiceStatus.warnings.warning)
              ? xInvoiceStatus.warnings.warning
              : [xInvoiceStatus.warnings.warning];
            xInvoiceStatus.warnings = warningArr;
          } else {
            xInvoiceStatus.warnings = [];
          }

          dataSource.x_invoice_status = xInvoiceStatus;
        }
      }

      dataSourceArr.push(dataSource);
    });

    return dataSourceArr;
  }

  expandSection(sectionId) {
    this.expandedSection = this.expandedSection === sectionId ? 0 : sectionId;
  }

  confirmRedownloadInvoice(id) {
    const confirmDialog = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: i18next.t("Redownload"),
        text: i18next.t("ConfirmXInvoiceRedownload")
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.redownloadXinvoice(id);
      }
    });
  }

  redownloadXinvoice(invoiceId) {
    const url = `/invoices/${invoiceId}/redownload`;
    const _self = this;

    this.isLoading = true;
    this.httpClient.put(this.apiEndPoint + url, {})
      .pipe(
        catchError(e => {_self.isLoading = false; return e; })
      ).subscribe((response: Params) => {
        _self.dialog.closeAll();
        _self.fetchInvoices();
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
