import {Component, Inject, OnInit} from '@angular/core';
import {DialogComponent} from "../../../../modals/dialog/dialog.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import * as _ from "lodash";

import {
  MatSort,
  MatDialog,
  MatMenuTrigger,
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material";

import i18next from "i18next";
import {catchError} from "rxjs/operators";
import {Params} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-dialog',
  templateUrl: './add-leitweg-id-modal.component.html',
  styleUrls: ['./add-leitweg-id-modal.component.scss']
})
export class AddLeitwegIdModalComponent implements OnInit {

  apiEndPoint: string;

  constructor(
    public httpClient: HttpClient,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

    addLeitwegForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      leitweg_id: new FormControl("", [Validators.required])
    });

    ngOnInit() {
    }

  close(action) {
    this.dialogRef.close(action);
  }

  getErrorMessage(prop) {
    return this.addLeitwegForm.controls[prop].hasError("required")
      ? i18next.t("RequiredField")
      : "";
  }

  async addLeitwegId() {
    if (!this.addLeitwegForm.valid) {
      this.addLeitwegForm.controls.name.markAsTouched();
      this.addLeitwegForm.controls.leitweg_id.markAsTouched();
      return false;
    }

    await this.saveLeitwegId(this.addLeitwegForm.value);
  }

  saveLeitwegId(formData): Promise<any> {
    let _self = this;
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiEndPoint + "leitweg-ids", formData)
        .pipe(
          catchError(e => {

            if (e.status === 400) {
              this.addLeitwegForm.controls.leitweg_id.setErrors({unique: true});
              console.log('logging error');
              return resolve();
            }
            if (_.find(e.error, { validation: "unique" })) {
              resolve();
              return;
            }

            reject();
            return e;
          })
        ).subscribe((response: Params) => {
          _self.close(true);
          resolve();
        }
      );
    });
  }
}
