import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.getIsAuthorized()
      .pipe(
        tap(isAuthorized => console.log('You tried to go to ' + state.url + ' and this guard said ' + isAuthorized)),
        map(isAuthorized => {
            if (isAuthorized) {
              return true;
            }

            this.router.navigate(['/']);
            return false;
          }
        )
      );
  }
}
