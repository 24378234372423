import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'app-guest-home',
  templateUrl: './guest-home.component.html',
  styleUrls: ['./guest-home.component.scss']
})
export class GuestHomeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    authService: AuthService,
  ) {
    authService.getIsAuthorized()
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(
      (isLogged: boolean) => {
        if (!isLogged) {
          return router.navigate(['/']);
        } else {
          return this.router.navigate(['/invoices/all']);
        }
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
