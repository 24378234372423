import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {InputComponent} from './fields/input/input.component';
import {RadioComponent} from './fields/radio/radio.component';

import {FormBuilderComponent} from './form-builder/form-builder.component';
import {FieldBuilderComponent} from './field-builder/field-builder.component';
import {I18NextModule} from 'angular-i18next';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {SelectComponent} from './fields/select/select.component';
import {CheckboxComponent} from './fields/checkbox/checkbox.component';
import {MatButtonModule, MatCheckboxModule, MatSelectModule, MatAutocompleteModule} from '@angular/material';
import {TextareaComponent} from './fields/textarea/textarea.component';
import {PasswordInputComponent} from './fields/password/password.component';
import {CompareValidatorDirective} from '../../directives/compare-validator';

@NgModule({
  imports: [
    I18NextModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule
  ],
  declarations: [
    InputComponent,
    RadioComponent,
    FormBuilderComponent,
    FieldBuilderComponent,
    SelectComponent,
    CheckboxComponent,
    TextareaComponent,
    PasswordInputComponent,
    CompareValidatorDirective
  ],
  exports: [FormBuilderComponent],
  providers: []
})
export class FormBuilderModule {
}
