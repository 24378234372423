export const environment = {
  production: false,
  api_host_url: 'https://staging.billx.de/',
  api_rest_prefix: 'rest/v1/',
  appName: 'billx',
  oidcConfigs: {
    issuer: 'https://europe-west2-invoicecloud-auth.cloudfunctions.net/app/api/auth',
  },
  services_config: {
    api_host: 'https://staging.payments.thinkinvoice.com/',
    api_rest_prefix: 'rest/v1/'
  },
  payment_service: {
    api_host: 'https://staging.payments.thinkinvoice.com/',
    api_rest_prefix: 'rest/v1/',
    serviceId: 2,
    stripe: {
      publishable_key: 'pk_test_DIEI7i8mvb0ffe8efprgRfC900CLMfmGoJ'
    },
    paypal: {
      client_id: 'AdYBDRDuF9KHw4oq39OdgDkUOanlp5PEywbm-VrQFOeBSxA12Gyz9lKnivPQUo3FkB0FvJ2ugKhKyZIE'
    }
  }
};
