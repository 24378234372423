import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthService} from "./services/auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(
    private titleService: Title,
    authService: AuthService
  ) {
    this.titleService.setTitle('Billx');
    authService.runInitialLoginSequence();
  }
}
