import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {ITableColumn, ITableData, ITablePaginator, ITableRowData} from "invoicecloud-modules";
import {catchError, take} from "rxjs/operators";
import {MetaInfoHelper} from "../../../../services/helpers/MetaInfoHelper";
import {InvoiceService} from "../../../../services/http/InvoiceService";
import i18next from "i18next";
import * as _ from 'lodash';
import {Params} from "@angular/router";

@Component({
  selector: 'app-all-invoices-v2',
  templateUrl: './all-invoices-v2.component.html',
  styleUrls: ['./all-invoices-v2.component.scss']
})
export class AllInvoicesV2Component implements OnInit {
  displayColumns: ITableColumn[] = [
    {slug: 'index', label: '#'},
    {slug: 'number', label: i18next.t('Number')},
    {slug: 'date', label: i18next.t('Date')},
    {slug: 'name', label: i18next.t('Name')},
    {slug: 'gross_price', label: i18next.t('Price')}
  ];
  paginator: ITablePaginator = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    length: 0,
    pageIndex: 0
  };

  tableData: ITableData;

  apiEndPoint: string;

  constructor(private metaInfoHelper: MetaInfoHelper,
              private invoiceService: InvoiceService) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  ngOnInit() {
    this.initTableData(this.paginator);
  }

  initTableData(paginator) {
    this.tableData = null;

    this.invoiceService.fetchInvoices({page: paginator.pageIndex + 1, perPage: paginator.pageSize})
    .pipe(
      take(1),
      catchError(error => {
        console.log(error);
        return error;
      })
    )
    .subscribe(
      (res: Params) => {
        let {data, total} = res;
        this.paginator.length = total;
        this.paginator.pageSize = paginator.pageSize;
        this.paginator.pageIndex = paginator.pageIndex;

        this.tableData = {
          displayColumns: this.displayColumns,
          rowsData: this.manageData(data, total),
          paginator: this.paginator
        };
      }
    );
  }

  manageData(data, total): ITableRowData[] {
    const dataSourceArr: ITableRowData[] = [];
    let lastIndex = total - (this.paginator.pageSize * this.paginator.pageIndex);

    data.forEach(element => {
      if (!element.invoice) {
        return;
      }

      let productList = '<ul>';
      _.forEach(element.invoice.lines, (item) => productList += `<li>${item.itemInformation.name}</li>`);
      productList += '</ul>';

      const dataSource: ITableRowData = {
        index: lastIndex--,
        number: element.invoice.number,
        date: element.invoice.created_at,
        name: element.invoice.seller.name,
        gross_price: element.invoice.documentTotals.total_amount_with_vat + ' ' + element.invoice.currency_code,
        detailExpanded: [
          {Title: i18next.t('SellerAddress'), Content: element.invoice.seller.address.address_line_1},
          {
            Title: i18next.t('ContactDetails'),
            Content:
              `${i18next.t('TelephoneNumber')}: ${element.invoice.seller.contact.telephone_number}<br>
              ${i18next.t('Email')}: ${element.invoice.seller.contact.email}<br>`
          },
          {
            Title: i18next.t('Items'),
            Content: productList
          }
        ]
      };

      dataSourceArr.push(dataSource);
    });

    return dataSourceArr;
  }
}
