import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
import {from, Observable} from "rxjs";
import {AuthService} from "./auth.service";

@Injectable()
export class BearerHttInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    const token = this.authService.accessToken;

    let newHeaders = request.headers;

    if (token) {
      newHeaders = request.headers.set('Authorization', token);
    }

    const requestCloneSettings = {
      headers: newHeaders,
    };

    let changedRequest = request.clone(requestCloneSettings);
    return next.handle(changedRequest).toPromise();
  }
}
