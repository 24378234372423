import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  filteredOptions: Observable<string[]>;

  @Input() autoCompleteOptions: Array<any> = [];
  @Input() field: any = {};
  @Input() group: FormGroup;
  @Output() controlEventEmitter = new EventEmitter<any>();
  @Output() controlConfirmEmitter = new EventEmitter<any>();
  _ = _;

  constructor() {
  }

  ngOnInit() {
    this.filteredOptions = this.group.valueChanges
      .pipe(
        startWith(''),
        map(val => val.length >= 1 ? this.autoCompleteInput(val) : [])
      );
  }

  filter(val: any): any {
    throw new Error("Method not implemented.");
  }

  onEvent(event, type) {
    if (this.field.hasOwnProperty('events') && _.indexOf(this.field.events, type) > -1) {
      this.controlEventEmitter.emit({
        field: this.field.name,
        eventType: type,
        event
      });
    }
  }

  autoCompleteInput(val: string): string[] {
    return this.autoCompleteOptions.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
}
