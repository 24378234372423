import {Injectable} from "@angular/core";

@Injectable()
export class MetaInfoHelper {
  getValueByKey(arrayOfMetaInfo, key) {
    const metaItem = arrayOfMetaInfo.find(metaInfo => {
      return metaInfo.key === key;
    });

    if (metaItem) {
      return metaItem.value;
    }

    return null;
  }

  transformMetaArrayToObject(array) {
    let objectMap = {};
    array.forEach((item) => {
      objectMap[item.key] = item.value;
    });

    return objectMap;
  }
}
