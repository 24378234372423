export class Integration {

  id;
  slug;
  logo;
  name;

  constructor(initializationParams) {
    if (initializationParams) {
      this.id = initializationParams.id;
      this.slug = initializationParams.slug_name;
      this.logo = initializationParams.logo;
      this.name =  initializationParams.name;
    }
  }
}

