export class BillomatFormBuilder {
  constructor() {}

  getFieldsAddUserIntegrationFields() {
    return [
      {
        type: 'control',
        control: 'input',
        name: 'token',
        label: 'Token',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'billomat_id',
        label: 'BillomatID',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      }
    ];
  }
}
