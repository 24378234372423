import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class LoggedCheckService implements CanActivate {

  constructor(
    private router: Router,
  ) {
  }

  canActivate() {
    if (localStorage.getItem('access_token')) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }
}
