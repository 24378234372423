import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-close-confirm',
  templateUrl: './close-confirm.component.html',
  styleUrls: ['./close-confirm.component.scss']
})
export class CloseConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CloseConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
  }

  close(action) {
    this.dialogRef.close(action);
  }
}
