export class DebitoorFormBuilder {
  constructor() {}

  getFieldsAddUserIntegrationFields() {
    return [
      {
        type: 'control',
        control: 'input',
        name: 'token',
        label: 'Token',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      }
    ];
  }
}
