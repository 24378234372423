export class EasyBillFormBuilder {
  constructor() {}

  getFieldsAddUserIntegrationFields() {
    return [
      {
        type: 'control',
        control: 'input',
        name: 'token',
        label: 'Token',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_name',
        label: 'SellerName',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_email',
        label: 'SellerEmail',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required',
          'email'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_address',
        label: 'SellerAddress',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_city',
        label: 'SellerCity',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_country_code',
        label: 'SellerCountryCode',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_post_code',
        label: 'SellerPostCode',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      },
      {
        type: 'control',
        control: 'input',
        name: 'seller_telephone_number',
        label: 'SellerTelephoneNumber',
        value: '',
        events: [
          'keypress',
          'blur'
        ],
        validator: [
          'required'
        ]
      }
    ];
  }
}
