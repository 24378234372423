import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MainComponent} from '../../components/billx/main/main.component';
import {NotFoundComponent} from '../../components/billx/not-found/not-found.component';
import {HomeComponent} from '../../components/billx/home/user-home/home.component';
import {GuestHomeComponent} from '../../components/billx/home/guest-home/guest-home.component';
import {AddUserIntegrationComponent} from '../../components/billx/integrations/add-user-integration/add-user-integration.component';
import {DevsComponent} from '../../components/billx/devs/devs.component';
import {ViewUserIntegrationComponent} from '../../components/billx/integrations/view-user-integration/view-user-integration.component';
import {InvoicesComponent} from '../../components/billx/invoices/invoices.component';
import { UserProfileComponent } from 'src/app/components/billx/user-profile/user-profile.component';
import { UserProfileMenuComponent } from 'src/app/components/billx/user-profile/user-profile-menu/user-profile-menu.component';
import { LeitwegIdListComponent } from 'src/app/components/billx/user-profile/leitweg-id-list/leitweg-id-list.component';
import { AllInvoicesComponent } from 'src/app/components/billx/invoices/all-invoices/all-invoices.component';
import {EmptyComponent} from "../../components/billx/home/empty/empty.component";
import {PricingComponent} from "../../components/billx/pricing/pricing.component";
import {CheckoutComponent} from "../../components/billx/checkout/checkout.component";
import {AuthGuard} from "../../services/auth/auth-guard.service";
import {AllInvoicesV2Component} from "../../components/billx/invoices/all-invoices-v2/all-invoices-v2.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: GuestHomeComponent
      },
      {
        path: 'add-integration/:integration_id',
        component: AddUserIntegrationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'integrations',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'your-integration/:user_integration_id',
        component: ViewUserIntegrationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'all',
            pathMatch: 'full'
          },
          {
            path: 'all',
            component: AllInvoicesComponent
          },
          {
            path: 'x-invoices',
            component: AllInvoicesComponent
          }
        ]
      },
      {
        path: 'user',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'profile',
            pathMatch: 'full'
          },
          {
            path: 'profile',
            component: UserProfileMenuComponent
          },
          {
            path: 'leitweg-id-list',
            component: LeitwegIdListComponent
          }
        ]
      },
      {
        path: 'pricing',
        component: PricingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'logout',
        component: EmptyComponent, // pick an empty component or create one
        canActivate: [AuthGuard]
      },
      {
        path: 'sign-in',
        component: EmptyComponent, // pick an empty component or create one
        canActivate: [AuthGuard]
      },
      {
        path: 'devs',
        component: DevsComponent
      },
      // All routes must be before the not found route
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppBillxRoutingModule {
}
