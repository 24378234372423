import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-devs',
  templateUrl: './devs.component.html',
  styleUrls: ['./devs.component.scss']
})
export class DevsComponent implements OnInit {

  public fields: any[] = [
    {
      type: 'control',
      control: 'input',
      name: 'name1',
      label: 'Example Of checkbox',
      validator: ['required', 'email']
    }
  ];

  public settings = {
    // disabledSubmit: true
  };

  constructor() {
  }

  ngOnInit() {
  }

  onSubmitForm(form) {
    console.log(form.valid);
    console.log(form);
  }

  controlEventListener(event) {
    console.log('controlsEventListener');
    console.log(event);
  }

}
