import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntegrationService} from '../../../../services/integrations/IntegrationService';
import {Integration} from '../../../../models/integrations/Integration';
import {MatDialog, MatDialogRef} from '@angular/material';
import {ConfirmComponent} from '../../../modals/confirm/confirm.component';
import i18next from 'i18next';
import {UserIntegration} from '../../../../models/integrations/UserIntegration';

@Component({
  selector: 'app-add-user-integration',
  templateUrl: './add-user-integration.component.html',
  styleUrls: ['./add-user-integration.component.scss']
})
export class AddUserIntegrationComponent implements OnInit {

  integrationId: string;
  newUserIntegration: any;
  userIntegrations: Array<UserIntegration>;
  isLoading: boolean;
  formBuilder;
  formFields;
  form;
  integration: Integration = new Integration({});
  dialogModal: MatDialogRef<ConfirmComponent, any>;
  isFormDataInitialized = false;

  constructor(
    private route: ActivatedRoute,
    private integrationService: IntegrationService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.integrationId = this.route.snapshot.params.integration_id;
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.initIntegration();
    await this.initUserIntegrations();
    const isAlreadyIntegrated = this.checkIfAlreadyIntegrated();
    if (isAlreadyIntegrated) {
      this.showAlreadyIntegratedModal();
      this.subscribeAfterCloseAlreadyIntegratedModal();
      return false;
    }

    this.initializeAddIntegrationForm();
    this.isLoading = false;
  }

  async initUserIntegrations() {
    try {
      this.userIntegrations = await this.integrationService.getUserIntegrations();
    } catch (e) {
      this.redirectToHome();
    }
  }

  async initIntegration() {
    try {
      this.integration = await this.integrationService.getIntegrationByID(this.integrationId);
    } catch (e) {
      this.redirectToHome();
    }
  }

  redirectToHome() {
    this.router.navigate(['invoices/all']);
  }

  checkIfAlreadyIntegrated() {
    if (this.userIntegrations.length > 0) {
      return true;
    }

    return false;
  }

  showAlreadyIntegratedModal() {
    this.dialogModal = this.dialog.open(ConfirmComponent, {
      width: '450px',
      data: {
        title: i18next.t('AlreadyIntegrated')
      }
    });
  }

  subscribeAfterCloseAlreadyIntegratedModal() {
    this.dialogModal.afterClosed().subscribe(result => {
      this.router.navigate(['/your-integration/' + this.userIntegrations[0].id]);
    });
  }

  showSuccessfulAddNewUserIntegrationModal() {
    this.dialogModal = this.dialog.open(ConfirmComponent, {
      width: '450px',
      data: {
        title: i18next.t('Success')
      }
    });
  }

  subscribeAfterCloseAddNewUserIntegrationModal() {
    this.dialogModal.afterClosed().subscribe(result => {
      this.router.navigate(['/your-integration/' + this.newUserIntegration.id]);
    });
  }

  initializeAddIntegrationForm() {
    this.formBuilder = this.integrationService.getIntegrationFormBuilder(this.integrationId);
    this.formFields = this.formBuilder.getFieldsAddUserIntegrationFields();
    this.isFormDataInitialized = true;
  }

  async addNewUserIntegration(data) {
    try {
      data.integrationID = this.integrationId;
      return await this.integrationService.addNewUserIntegration(data);
    } catch (e) {
      console.log(e);
    }
  }

  async submitAddUserIntegrationForm(form) {
    this.isLoading = true;
    this.form = form;
    if (form.invalid) {
      this.isLoading = false;
      return false;
    }

    const isValid = await this.checkUserIntegrationIsValid(form.value);
    if (!isValid) {
      this.isLoading = false;
      return false;
    }
    this.newUserIntegration = await this.addNewUserIntegration(form.value);
    this.showSuccessfulAddNewUserIntegrationModal();
    this.subscribeAfterCloseAddNewUserIntegrationModal();
    this.isLoading = false;
  }

  async checkUserIntegrationIsValid(data) {
    try {
      data.integration_id = this.integrationId;
      // this field is hidden for now and this is default value - remove this line later
      data.name = this.integrationId;
      await this.integrationService.checkUserIntegrationIsValid(data);
      return true;
    } catch (e) {
      this.showInvalidUserIntegrationDataModal();
      this.subscribeInvalidUserIntegrationDataModal();
      return false;
    }
  }

  showInvalidUserIntegrationDataModal() {
    this.dialogModal = this.dialog.open(ConfirmComponent, {
      width: '450px',
      data: {
        title: i18next.t('InvalidUserIntegrationCredentials')
      }
    });
  }
  subscribeInvalidUserIntegrationDataModal() {
    this.dialogModal.afterClosed().subscribe(result => {
    });
  }

  controlEventListener($event: any) {
  }
}
