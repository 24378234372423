import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IntegrationService} from '../../../../services/integrations/IntegrationService';
import {MatDialog} from '@angular/material';
import {UserIntegration} from '../../../../models/integrations/UserIntegration';

@Component({
  selector: 'app-view-user-integration',
  templateUrl: './view-user-integration.component.html',
  styleUrls: ['./view-user-integration.component.scss']
})
export class ViewUserIntegrationComponent implements OnInit {

  isLoading: boolean;
  userIntegrationId: number;
  userIntegration: UserIntegration;
  envData: any;

  constructor(
    private route: ActivatedRoute,
    private integrationService: IntegrationService,
    private router: Router,
    public dialog: MatDialog) {
    this.userIntegrationId = this.route.snapshot.params.user_integration_id;
    this.initializeEnvData();
  }

  async ngOnInit() {
    this.isLoading = true;
    await this.initUserIntegration();
    this.isLoading = false;
  }

  initializeEnvData() {
    this.envData = {};
    this.envData.email = '';
  }

  async initUserIntegration() {
    try {
      this.userIntegration = await this.integrationService.getUserIntegrationById(this.userIntegrationId);
      this.envData = await this.integrationService.getEnvData(this.userIntegrationId);
    } catch (e) {
      this.redirectToHome();
    }
  }

  redirectToHome() {
    this.router.navigate(['invoices/all']);
  }

  async syncInvoices() {
    try {
      this.isLoading = true;
      await this.integrationService.sync();
      this.redirectToHome();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }

  async deleteUserIntegration() {
    try {
      this.isLoading = true;
      await this.integrationService.deleteUserIntegrationById(this.userIntegrationId);
      this.redirectToHome();
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }
}
