import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-field-builder',
  templateUrl: './field-builder.component.html',
  styleUrls: ['./field-builder.component.scss']
})
export class FieldBuilderComponent implements OnInit {

  @Input() autoCompleteOptions: any[] = [];
  @Input() fields: any[] = [];
  @Input() fieldName: string;
  @Input() currentLevelGroup: FormGroup;
  @Input() formSubmitted;
  @Output() controlEventEmitter = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  controlEventListener(event) {
    this.controlEventEmitter.emit(event);
  }
}
