import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { fadeInAnimationAbsolute } from 'src/app/animations';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    fadeInAnimationAbsolute,
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state(
        "expanded",
        style({
          height: "auto",
          visibility: "visible",
          padding: "20px 5px 20px 5px",
          margin: "10px 20px",
          border: "5px solid #efefef"
        })
      ),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ],
  host: { "[@fadeInAnimationAbsolute]": "" },
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
   }

  ngOnInit() {
  }

}
