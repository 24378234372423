import {Component, OnDestroy} from '@angular/core';
import {IHeaderData, IFooterData} from "invoicecloud-modules";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";
import i18next from "i18next";
import {User} from "../../../models/user";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy {

  private destroy$ = new Subject();

  private userSubject$ = new BehaviorSubject<User>(undefined);
  public user$ = this.userSubject$.asObservable();

  public headerData: IHeaderData = {
    client_id: 'Billx',
    backgroundColor: '#3f51b5',
    defaultColor: '#e8f0fe',
    fontColor: '#fff',
    buttonFontColor: '#000',
    routes: [
      {name: i18next.t('Invoices'), src: 'invoices/all'},
      {name: i18next.t('Integrations'), src: 'integrations'},
      {name: i18next.t('Pricing'), src: 'pricing'}
    ],
    registerButton: true
  };

  public footerData: IFooterData = {
    client_id: 'Billx',
    backgroundColor: '#3f51b5',
    defaultColor: '#e8f0fe',
    fontColor: '#fff'
  };

  constructor(public authService: AuthService) {
    this.initUser();
  }

  private initUser() {
    this.authService.getLoggedUser().pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => this.userSubject$.next(user));
  }

  changeLang(event) {
    i18next.changeLanguage(event.lang).then(() => {
      window.location.reload();
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
