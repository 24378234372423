import {
  trigger,
  state,
  animate,
  transition,
  style,
  query,
  animateChild,
  group
} from "@angular/animations";

export const fadeInAnimation = trigger("fadeInAnimation", [
  transition(":enter", [
    style({ opacity: 0 }),
    animate("0.5s", style({ opacity: 1 }))
  ])
  // transition(':enter', [
  //   style({position: 'absolute', width: '100%', transform: 'translateX(100%)'}),
  //   animate('0.3s', style({transform: 'translateX(0%)'}))
  // ]),
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.2s', style({transform: 'translateX(-100%)'}))
  // ])
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.2s', style({transform: 'translateY(+100%)'}))
  // ])
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.5s', style({opacity: 0}))
  // ])
]);

export const fadeInAnimationAbsolute = trigger("fadeInAnimationAbsolute", [
  transition(":enter", [
    style({ position: "absolute", opacity: 0 }),
    animate("0.5s", style({ opacity: 1 }))
  ])
  // transition(':enter', [
  //   style({position: 'absolute', width: '100%', transform: 'translateX(100%)'}),
  //   animate('0.3s', style({transform: 'translateX(0%)'}))
  // ]),
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.2s', style({transform: 'translateX(-100%)'}))
  // ])
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.2s', style({transform: 'translateY(+100%)'}))
  // ])
  // transition(':leave', [
  //   style({position: 'absolute', width: '100%', top: 0}),
  //   animate('0.5s', style({opacity: 0}))
  // ])
]);
