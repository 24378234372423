import {HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {authConfig} from './auth-config';
import {AuthGuardWithForcedLogin} from './auth-guard-with-forced-login.service';
import {AuthGuard} from './auth-guard.service';
import {authModuleConfig} from './auth-module-config';
import {AuthService} from './auth.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [
        HttpClientModule,
        OAuthModule.forRoot(),
    ],
    providers: [
        AuthService,
        AuthGuard,
        AuthGuardWithForcedLogin,
    ],
})
export class AuthModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {provide: AuthConfig, useValue: authConfig},
                {provide: OAuthModuleConfig, useValue: authModuleConfig},
                {provide: OAuthStorage, useFactory: storageFactory},
            ]
        };
    }
}
