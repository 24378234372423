import {AppBillxRoutingModule} from './app-billx-routing.module';

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MainComponent} from '../../components/billx/main/main.component';
import {HomeComponent} from '../../components/billx/home/user-home/home.component';
import {NotFoundComponent} from '../../components/billx/not-found/not-found.component';
import {GuestHomeComponent} from '../../components/billx/home/guest-home/guest-home.component';
import {AddUserIntegrationComponent} from '../../components/billx/integrations/add-user-integration/add-user-integration.component';
import {DevsComponent} from '../../components/billx/devs/devs.component';
import {ViewUserIntegrationComponent} from '../../components/billx/integrations/view-user-integration/view-user-integration.component';
import {BrowserModule} from '@angular/platform-browser';
import {I18NextModule} from 'angular-i18next';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormBuilderModule} from '../form-builder/form-builder.module';
import {IntegrationService} from '../../services/integrations/IntegrationService';
import {LoaderComponent} from '../../components/globals/loader/loader.component';
import {StringHelperService} from '../../services/helpers/StringHelperService';
import {MetaInfoHelper} from '../../services/helpers/MetaInfoHelper';
import { InvoicesComponent } from '../../components/billx/invoices/invoices.component';
import { PdfPreviewComponent } from '../../components/billx/pdf-preview/pdf-preview.component';
import {
  MatDialogModule,
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatInputModule,
  MatTooltipModule,
  MatSelectModule,
  MatFormFieldModule,
  MatAutocompleteModule
} from '@angular/material';
import { DialogComponent } from 'src/app/components/modals/dialog/dialog.component';
import { ConfirmComponent } from 'src/app/components/modals/confirm/confirm.component';
import {AvatarModule} from 'ngx-avatar';
import { SendXInvoiceComponent } from '../../components/billx/send-x-invoice/send-x-invoice.component';
import { UserProfileComponent } from '../../components/billx/user-profile/user-profile.component';
import { UserProfileMenuComponent } from '../../components/billx/user-profile/user-profile-menu/user-profile-menu.component';
import { LeitwegIdListComponent } from '../../components/billx/user-profile/leitweg-id-list/leitweg-id-list.component';
import { AllInvoicesComponent } from '../../components/billx/invoices/all-invoices/all-invoices.component';
import {environment} from "../../../environments/environment";
import {InvoiceService} from "../../services/http/InvoiceService";
import {BearerHttInterceptor} from "../../services/auth/BearerHttInterceptor";
import {InvoiceCloudModule, SharedModule} from "invoicecloud-modules";
import {EmptyComponent} from "../../components/billx/home/empty/empty.component";
import {PricingComponent} from "../../components/billx/pricing/pricing.component";
import {CheckoutComponent} from "../../components/billx/checkout/checkout.component";
import {AllInvoicesV2Component} from "../../components/billx/invoices/all-invoices-v2/all-invoices-v2.component";

import {AuthService} from "../../services/auth/auth.service";
import {AuthModule} from "../../services/auth/auth.module";
// tslint:disable-next-line:max-line-length
import {AddLeitwegIdModalComponent} from "../../components/billx/user-profile/leitweg-id-list/add-leitweg-id-modal/add-leitweg-id-modal.component";

@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    NotFoundComponent,
    GuestHomeComponent,
    AddUserIntegrationComponent,
    DevsComponent,
    ViewUserIntegrationComponent,
    LoaderComponent,
    InvoicesComponent,
    PdfPreviewComponent,
    SendXInvoiceComponent,
    UserProfileComponent,
    UserProfileMenuComponent,
    LeitwegIdListComponent,
    AllInvoicesComponent,
    EmptyComponent,
    PricingComponent,
    CheckoutComponent,
    AllInvoicesV2Component,
    AddLeitwegIdModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppBillxRoutingModule,
    BrowserAnimationsModule,
    I18NextModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FormBuilderModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    AvatarModule,
    AuthModule.forRoot(),
    InvoiceCloudModule.forRoot(environment),
    SharedModule
  ],
  entryComponents: [DialogComponent, ConfirmComponent, PdfPreviewComponent, SendXInvoiceComponent, AddLeitwegIdModalComponent],
  providers: [
    IntegrationService,
    StringHelperService,
    MetaInfoHelper,
    InvoiceService,
    AuthService,
    {  provide: HTTP_INTERCEPTORS,
      useClass: BearerHttInterceptor,
      multi: true
    },
  ]
})
export class AppBillxModule {

}
