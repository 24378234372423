import {Injectable} from '@angular/core';
import {Integration} from '../../models/integrations/Integration';
import {EasyBillFormBuilder} from './form-builders/easybill/EasyBillFormBuilder';
import {DebitoorFormBuilder} from './form-builders/debitoor/DebitoorFormBuilder';
import {BillomatFormBuilder} from './form-builders/billomat/BillomatFormBuilder';
import {SevDeskFormBuilder} from './form-builders/sevdesk/SevDeskFormBuilder';
import {UserIntegration} from '../../models/integrations/UserIntegration';
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Params} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable()
export class IntegrationService {
  apiEndPoint: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  getIntegrationFormBuilder(integrationId) {
    switch (integrationId) {
      case '1': return new EasyBillFormBuilder();
      case '2': return new DebitoorFormBuilder();
      case '3': return new BillomatFormBuilder();
      case '4': return new SevDeskFormBuilder();
      default: return null;
    }
  }

  getIntegrations(): Promise<Array<Integration>> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'integrations')
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
        (response: Params) => {
          let integrations: Array<Integration> = [];
          for (const integrationIndex of Object.keys(response.data)) {
            integrations.push(new Integration(response.data[integrationIndex]));
          }

          return resolve(integrations);
        });
    });
  }

  getIntegrationByID(integrationId): Promise<Integration>  {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'integrations/' + integrationId)
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
        (response: Params) => {
          const integration = new Integration({
            id: response.id,
            slug_name: response.slug_name,
            logo: response.logo,
            name: response.name
          });
          return resolve(integration);
        });
    });
  }

  getUserIntegrations(): Promise<Array<UserIntegration>> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'user-integrations')
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
          const userIntegrations: Array<UserIntegration> = [];
          for (const userIntegrationIndex of Object.keys(response.data)) {
            userIntegrations.push(new UserIntegration(response.data[userIntegrationIndex]));
          }

          return resolve(userIntegrations);
        });
    });
  }

  addNewUserIntegration(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiEndPoint + 'user-integrations', data)
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
            return resolve(response);
        });
    });
  }

  getUserIntegrationById(userIntegrationId): Promise<UserIntegration> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'user-integrations/' + userIntegrationId)
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
          const userIntegration = new UserIntegration(response);
          return resolve(userIntegration);
        });
    });
  }

  deleteUserIntegrationById(userIntegrationId) {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(this.apiEndPoint + 'user-integrations/' + userIntegrationId)
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
            return resolve(response);
        });
    });
  }

  sync() {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'integrations/sync/user-integrations')
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
            return resolve(response);
        });
    });
  }

  checkUserIntegrationIsValid(data) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(this.apiEndPoint + 'user-integrations/is-valid-integration-data', data)
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
            return resolve(response);
        });
    });
  }

  getEnvData(userIntegrationId) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.apiEndPoint + 'user-integrations/' + userIntegrationId + '/get-env-data')
        .pipe(
          catchError( err => { console.log(err); reject(err); return err; })
        )
        .subscribe(
          (response: Params) => {
          return resolve(response);
        });
    });
  }
}
