import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Params} from "@angular/router";
import {Observable} from "rxjs";

@Injectable()
export class InvoiceService {
  private readonly apiEndPoint: string;
  constructor(
    private httpClient: HttpClient
  ) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  fetchInvoices(paginator: Params): Observable<Params> {
    return this.httpClient.get(this.apiEndPoint + 'invoices', {params: paginator});
  }
}
