import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-password-input',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordInputComponent implements OnInit {

  @Input() field: any = {};
  @Input() group: FormGroup;
  @Output() controlEventEmitter = new EventEmitter<any>();
  @Output() controlConfirmEmitter = new EventEmitter();
  _ = _;

  constructor() {
  }

  ngOnInit() {

    // if (this.field.name === 'password') {
    //   const subsctiption: Subscription = this.group.get('password').valueChanges.subscribe(() => {
    //     console.log('KOLIOOOO');
    //     // subsctiption.unsubscribe();
    //   });
    // }

  }

  onEvent(event, type) {
    if (this.field.hasOwnProperty('events') && _.indexOf(this.field.events, type) > -1) {
      this.controlEventEmitter.emit({
        field: this.field.name,
        eventType: type,
        event: event
      });
    }

    // if (type === 'keypress') {
    //   // this.controlConfirmEmitter.emit();
    //   console.log(this.group);
    //   this.group.get('password').updateValueAndValidity();
    //   this.group.get('password_confirm').updateValueAndValidity();
    //   this.group.get('password').markAsTouched();
    //   this.group.get('password_confirm').markAsTouched();
    // }
    // // if (type === 'blur') {
    // //   console.log(this.group);
    // // }
  }
}
