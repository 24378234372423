import { Component, OnInit } from '@angular/core';
import { fadeInAnimationAbsolute } from 'src/app/animations';
import {Observable, of} from "rxjs";
import {Params} from "@angular/router";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss'],
  animations: [
    fadeInAnimationAbsolute
  ],
  host: { "[@fadeInAnimationAbsolute]": "" }
})
export class UserProfileMenuComponent implements OnInit {
  user$: Observable<Params>;
  constructor(
    private authService: AuthService
  ) {
    this.user$ = authService.getLoggedUser();
  }

  ngOnInit() {
    // this.user = this.authService.getUserData();
  }

}
