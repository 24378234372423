import {Integration} from './Integration';

export class UserIntegration {

  id;
  userId;
  integrationId;
  name;
  token;
  integration = new Integration({});

  constructor(initializationParams = null) {
    this.id = initializationParams.id;
    this.userId = initializationParams.user_id;
    this.integrationId = initializationParams.integration_id;
    this.token =  initializationParams.token;
    this.name =  initializationParams.name;
    this.integration = new Integration(initializationParams.integration);
  }
}

