import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatSort, MatDialog } from "@angular/material";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { fadeInAnimationAbsolute } from 'src/app/animations';
import i18next from "i18next";
import { DialogComponent } from "src/app/components/modals/dialog/dialog.component";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {catchError} from "rxjs/operators";
import {Params} from "@angular/router";
import {AddLeitwegIdModalComponent} from "./add-leitweg-id-modal/add-leitweg-id-modal.component";

@Component({
  selector: "app-leitweg-id-list",
  templateUrl: "./leitweg-id-list.component.html",
  styleUrls: ["./leitweg-id-list.component.scss"],
  animations: [
    fadeInAnimationAbsolute,
    trigger("detailExpand", [
      state(
        "collapsed, void",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state(
        "expanded",
        style({
          height: "auto",
          visibility: "visible",
          padding: "20px 5px 20px 5px",
          margin: "10px 20px",
          border: "5px solid #efefef"
        })
      ),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
      transition(
        "expanded <=> void",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ],
  host: { "[@fadeInAnimationAbsolute]": "" }
})
export class LeitwegIdListComponent implements OnInit {
  isLoading = false;
  columnsToDisplay: string[] = ["name", "leitwegId", "add-delete"];
  dataSource: MatTableDataSource<any>;
  tableRowLoading: boolean = false;
  paginator = {
    pageSizeOptions: [10, 20, 50],
    pageSize: 10,
    length: 0,
    pageIndex: 0
  };
  leitwegName: string;
  expandedElement: number = 0;
  leitwegIds: Array<any> = [];
  @ViewChild(MatSort) sort: MatSort;
  apiEndPoint: string;

  constructor(private httpClient: HttpClient, public dialog: MatDialog) {
    this.apiEndPoint = environment.api_host_url + environment.api_rest_prefix;
  }

  ngOnInit() {
    this.getLeitwegIds(this.paginator);
  }

  getLeitwegIds(paginator) {
    let _self = this;
    let url = this.apiEndPoint + `leitweg-ids?page=${paginator.pageIndex + 1}&perPage=${
      paginator.pageSize
    }`;
    this.isLoading = true;

    this.httpClient.get(url)
      .pipe(
        catchError(e => { console.log(e); return e; })
      ).subscribe ( (response: Params) => {
        let data = response.data;
        _self.leitwegIds = [];

        data.forEach(element => {
          element.disabled = true;
          _self.leitwegIds.push(element);
        });

        _self.dataSource = new MatTableDataSource(data);
        _self.dataSource.sort = _self.sort;
        _self.paginator.length = response.total;
        _self.paginator.pageIndex = paginator.pageIndex;

        _self.isLoading = false;
      },
    );
  }

  enableElement(id) {
    this.dataSource.data.forEach(element => {
      element.disabled = element.id === id ? false : true;
    });
  }

  confirmRemoveLeitwegID(id) {
    const deleteDialog = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: i18next.t("DeleteLeitwegID"),
        text: i18next.t("DeleteLeitwegIDConfirm")
      }
    });

    deleteDialog.afterClosed().subscribe(result => {
      if (result) {
        this.removeLeitwegId(id);
      }
    });
  }

  removeLeitwegId(id) {
    let _self = this;
    this.httpClient.delete(this.apiEndPoint + "leitweg-ids/" + id)
      .pipe(
        catchError(e => { _self.getLeitwegIds(_self.paginator); console.log(e); return e; })
      ).subscribe ( (response: Params) => {
        _self.getLeitwegIds(_self.paginator);
      },
    );
  }

  editLeitwegId(id) {
    let _self = this;
    this.httpClient.put(this.apiEndPoint + "leitweg-ids/" + id, { name: this.leitwegName })
      .pipe(
        catchError(e => { _self.getLeitwegIds(_self.paginator); console.log(e); return e; })
      ).subscribe ( (response: Params) => {
        _self.getLeitwegIds(_self.paginator);
      });
  }

  addLeitwegId() {
    const addLeitwegIdDialog = this.dialog.open(AddLeitwegIdModalComponent, {
      width: "700px",
      data: {}
    });

    addLeitwegIdDialog.afterClosed().subscribe(result => {
      this.getLeitwegIds(this.paginator);
    });
 }
}
